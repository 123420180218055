import React from "react";
import ReactDOM from "react-dom/client";
import { initializeIcons } from "@fluentui/react";
import "./index.css";
import Layout from "./pages/layout/Layout";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./auth/authConfig";
import { MsalProvider } from "@azure/msal-react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
// import { createBrowserHistory } from "history";

initializeIcons();

// App insights
// const browserHistory = createBrowserHistory({ basename: '' });
var reactPlugin = new ReactPlugin();
// *** Add the Click Analytics plug-in. ***
// var clickPluginInstance = new ClickAnalyticsPlugin();
//    var clickPluginConfig = {
//      autoCapture: true
// };
var appInsights = new ApplicationInsights({
    config: {
        connectionString: import.meta.env.VITE_APPLICATIONINSIGHTS_CONNECTIONSTRING,
        // *** If you're adding the Click Analytics plug-in, delete the next line. ***
        extensions: [reactPlugin]
        // *** Add the Click Analytics plug-in. ***
        // extensions: [reactPlugin, clickPluginInstance],
        // extensionConfig: {
        //   [reactPlugin.identifier]: { history: browserHistory },
        // // *** Add the Click Analytics plug-in. ***
        //   [clickPluginInstance.identifier]: clickPluginConfig
        // }
    }
});
appInsights.loadAppInsights();
appInsights.trackPageView();

// Create an instance of PublicClientApplication
const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <Layout />
        </MsalProvider>
    </React.StrictMode>
);
