import { Example } from "./Example";

import styles from "./Example.module.css";

export type ExampleModel = {
    text: string;
    value: string;
};

const EXAMPLES: ExampleModel[] = [
    { text: import.meta.env.VITE_DEFAULT_QUESTION1, value: import.meta.env.VITE_DEFAULT_QUESTION1 },
    { text: import.meta.env.VITE_DEFAULT_QUESTION2, value: import.meta.env.VITE_DEFAULT_QUESTION2 },
    { text: import.meta.env.VITE_DEFAULT_QUESTION3, value: import.meta.env.VITE_DEFAULT_QUESTION3 },
    { text: import.meta.env.VITE_DEFAULT_QUESTION4, value: import.meta.env.VITE_DEFAULT_QUESTION4 }
];

interface Props {
    onExampleClicked: (value: string) => void;
}

export const ExampleList = ({ onExampleClicked }: Props) => {
    return (
        <ul className={styles.examplesNavList}>
            {EXAMPLES.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
